import React from 'react';
import './Sidebar.css';
import { IoHomeOutline, IoLogoReact, IoFlaskOutline,
  IoConstructSharp } from 'react-icons/io5';
import { HiOutlineNewspaper } from 'react-icons/hi';
import { NavLink } from 'react-router-dom';

class Sidebar extends React.Component {
  render() {
    return (
      <section className="Sidebar">
        <nav>
          <NavLink to="/" activeClassName="active" exact>
            <IoHomeOutline />
            Home
          </NavLink>
          <NavLink to="/conteudo" activeClassName="active">
            <HiOutlineNewspaper />
            Conteúdo
          </NavLink>
          <NavLink to="/tecnologias" activeClassName="active">
            <IoLogoReact />
            Tecnologias
          </NavLink>
          <NavLink to="/projetos" activeClassName="active" exact>
            <IoFlaskOutline />
            Projetos
          </NavLink>
          <NavLink to="/desenvolvedor" activeClassName="active" exact>
            <IoConstructSharp />
            Desenvolvedor
          </NavLink>
        </nav>
      </section>
    );
  }
}

export default Sidebar;
