import React, { Component } from 'react';
import Content from '../components/Content';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

class Desenvolvedores extends Component {
  render() {
    return (
      <>
        <Header />
        <main>
          <Sidebar />
          <Content>
            <h1>DEV Jorge Araujo de jesus</h1>
          </Content>
        </main>
      </>
    );
  }
}
export default Desenvolvedores;
