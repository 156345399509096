import React from 'react';
import { Link } from 'react-router-dom';
import Content from '../components/Content';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

function Conteudo() {
  return (
    <>
      <Header />
      <main>
        <Sidebar />
        <Content>
          <h1>CONTEÚDO</h1>

          <div><Link to="/conteudo/fundamentals">FUNDAMENTOS</Link></div>
          <div><Link to="/conteudo/front">FRONT-END</Link></div>
          <div><Link to="/conteudo/back">BACK-END</Link></div>
          <div><Link to="/conteudo/css">CIÊNCIA DA COMPUTAÇÃO</Link></div>
        </Content>
      </main>
    </>
  );
}

export default Conteudo;
