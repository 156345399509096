import React from 'react';
import Content from '../components/Content';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

function Tecnologias(props) {
  console.log(props);
  return (
    <>
      <Header />
      <main>
        <Sidebar />
        <Content>
          <h1>Html</h1>
          <h1>CSS</h1>
          <h1>JavaScript</h1>
          <h1>Node</h1>
          <h1>React</h1>
        </Content>
      </main>
    </>
  );
}

export default Tecnologias;
