import './App.css';
import { Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Tecnologias from './pages/Tecnologias';
import Projetos from './pages/Projetos';
import Desenvolvedores from './pages/Desenvolvedores';
import PageNotFound from './pages/PageNotFound';
// import ConteudoDetalhes from './pages/ConteudoDetalhes';
import Conteudo from './pages/Conteudo';

function App() {
  return (
    // colocarei as rotas aqui, pois é nesse componente que quero renderizar outros componentes
    // Switch garante que apenas 1 rota seja lida por vez
    // path: caminho da url
    // component: o nome do componente a ser renderizado
    <Switch>
      <Route exact path="/" component={ Home } />
      <Route path="/conteudo" component={ Conteudo } />
      <Route path="/tecnologias" component={ Tecnologias } />
      <Route path="/projetos" component={ Projetos } exact />
      <Route path="/desenvolvedor" component={ Desenvolvedores } />
      <Route path="*" component={ PageNotFound } />
    </Switch>

  );
}

export default App;
